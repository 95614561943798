<template>
  <div class="login">
    <div class="login-form">
      <div
        :class="{ 'login-form__hellow-gashu-text_show': helloGashuShow }"
        class="login-form__hellow-gashu-text"
      >
        Привет от <span>Гашу:)</span>
      </div>
      <img
        class="logo"
        :src="require(`assets/images/${logo}.svg?inline`)"
        @click="onClickLogo"
      >

      <div class="crm-input-block">
        <base-input
          v-model="$v.login.$model"
          :error="$v.login.$error"
          :errors="loginErrors"
          :input-style="{
            padding: '21px 20px',
          }"
          placeholder="Логин"
          class="login-form__input"
          fluid
        />

        <base-input
          ref="password"
          v-model="$v.password.$model"
          :error="$v.password.$error"
          :errors="passwordErrors"
          :input-style="{
            padding: '21px 20px',
          }"
          :type="showPassword ? 'text' : 'password'"
          placeholder="Пароль"
          class="login-form__input"
          fluid
          v-on="{
            blur: onblurPassword,
          }"
        >
          <template
            v-slot:suffix
          >
            <icon-eye
              :class="[ 'icon-eye', { active: showPassword }]"
              @click="toggleShowPassword"
            />
          </template>
        </base-input>
      </div>

      <base-checkbox
        v-model="saveAuth"
        class="login-form__checkbox"
        align-top
      >
        Сохранить логин и пароль для входа в профиль
      </base-checkbox>

      <base-button
        :type="$const.SECONDARY_BUTTON"
        class="login-form__button"
        @click="signIn"
      >
        ВОЙТИ
      </base-button>
    </div>
  </div>
</template>

<script>
import {
  BaseButton,
  BaseInput,
  BaseCheckbox,
} from '@/components/base';

import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import { DOMAIN_SETTINGS } from '@/helpers/consts';

import { showErrorCustomMessage } from '@/helpers/messages';

import IconEye from 'assets/images/eye_1.svg';
import { APP_SETTINGS } from '@/app.config';
import HubClientService from '@/services/core/hub';
import { authService } from '@/services';

export default {
  name: 'Login',
  page: {
    title: 'CRM Doctis - Вход',
  },
  components: {
    BaseInput,
    BaseButton,
    BaseCheckbox,
    IconEye,
  },
  mixins: [validationMixin],
  props: {
    redirectFrom: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      login: '',
      password: '',
      saveAuth: true,
      showPassword: false,
      passwordSelection: {
        start: 0,
        end: 0,
      },
      countLogoClicks: 0,
      helloGashuShow: false,
    };
  },
  validations: {
    login: {
      required,
    },
    password: {
      required,
    },
  },
  computed: {
    logo() {
      return DOMAIN_SETTINGS[APP_SETTINGS || 'default'].logo;
    },
    loginErrors() {
      const errors = [];

      if (!this.$v.login.required) {
        errors.push('Поле не может быть пустым');
      }

      return errors;
    },
    passwordErrors() {
      const errors = [];

      if (!this.$v.password.required) {
        errors.push('Поле не может быть пустым');
      }

      return errors;
    },
  },
  async created() {
    if (this.$route.query?.token) this.signInWithToken();
    if (this.$route.query?.error) {
      showErrorCustomMessage(this.$route.query?.error);
    }
  },
  methods: {
    onClickLogo() {
      this.countLogoClicks += 1;
      if (this.countLogoClicks === 3) {
        this.helloGashuShow = true;

        setTimeout(() => {
          this.countLogoClicks = 0;
          this.helloGashuShow = false;
        }, 3500);
      }
    },
    async focusPassword() {
      this.$refs.password.$refs.input.focus();
      await this.$nextTick();
      this.$refs.password.$refs.input.setSelectionRange(this.passwordSelection.start, this.passwordSelection.end);
    },
    async toggleShowPassword() {
      this.showPassword = !this.showPassword;
      await this.$nextTick();
      this.focusPassword();
    },
    onblurPassword(event) {
      this.passwordSelection.start = event.target.selectionStart;
      this.passwordSelection.end = event.target.selectionEnd;
    },
    async signIn() {
      this.$v.$touch();
      if (this.$v.$error) return;

      try {
        await this.$store.dispatch('Auth/login', {
          login: this.login,
          password: this.password,
          saveAuth: this.saveAuth,
        });
      } catch (err) {
        console.log(err);

        const urlRegex = /(https?:\/\/[^\s]+)/g;
        const error = err?.response?.data?.error || '';
        const errorWithLink = error.replace(urlRegex, (url) => `<a href="${url}" target="_blank">${url}</a>`);

        showErrorCustomMessage(errorWithLink);
      }

      try {
        const { token } = this.$store.state.Auth;
        if (token) {
          await HubClientService.init(this.$store.state.Auth.token);
        }
      } catch (e) {
        console.log(e);
      }

      await this.$router.push('/');

      if (localStorage.getItem('internal')) {
        window.location.reload();
      }
    },
    async signInWithToken() {
      try {
        const jwtToken = this.$route.query?.token;

        const { role, loginDecoded } = authService.decodeToken(jwtToken);

        await this.$store.dispatch('Auth/updateUserData', {
          saveAuth: this.saveAuth, token: jwtToken, role, loginDecoded,
        });
        this.$router.push('/');
      } catch (err) {
        console.error(err);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.login {
  @include default-flex-prop;
  width: 100%;
  height: 100%;
}
.login-form {
  position: relative;
  color: $black-light;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 50px 70px;
  width: 430px;
  background: $white;
  box-shadow: 0px 1px 2px $gray-shadow;
  box-sizing: border-box;
  border-radius: 4px;

  &__hellow-gashu-text {
    position: absolute;
    opacity: 0;
    font-weight: 500;
    font-size: 17px;
    transition: .5s;
    left: 50%;
    transform: translate(-50%, -50%);
    top: 80px;
    pointer-events: none;

    & span {
      color: black;
      transition: 1s;
    }

    &_show {
      opacity: 1;
      top: 53px;

      & span {
        color: orange;
      }
    }
  }
}
.logo {
  width: 100%;
  max-height: 70px;
  margin-bottom: 50px;
}
.crm-input-block {
  width: 100%;
}
.login-form__input:first-child {
  margin-bottom: 25px;
}
.login-form__button {
  margin-top: 30px;
  font-weight: 500;
  font-size: 16px;
}
.login-form__checkbox {
  margin-top: 20px;
  width: 100%;
}
::v-deep.icon-eye {
  cursor: pointer;

  &:hover,
  &.active {
    path {
      fill: $blue;
    }
  }
}
</style>
